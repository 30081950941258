/* You can add global styles to this file, and also import other style files */
@import "./assets/css/general.less";
@import "./assets/css/style.css";
@import "./assets/css/bootstrap-grid.min.css";
@import "./assets/css/bootstrap.min.css";
@import "./assets/css/bootstrap-reboot.min.css";
@import "./assets/css/font-awesome.min.css";
// @import "./assets/css/fine-uploader.min.css";
// @import "./assets/css/jquery.fileupload-ui.css";
@import "./assets/css/jquery.fileupload.css";
@import "./assets/css/jquery.formstyler.css";
@import "./assets/css/jquery.formstyler.theme.css";
@import "./assets/css/perfect-scrollbar.css";
@import "./assets/css/nouislider.min.css";
@import "./assets/css/slick.css";
@import "./assets/css/slick-theme.css";
@import "./assets/css/aksFileUpload.min.css";
@import '~@angular/cdk/overlay-prebuilt.css';
@import "~@marcreichel/angular-carousel/css/main.css";
@import "~@marcreichel/angular-carousel/themes/dots.css";

.item_labels{
  z-index: 500;
}

gallery {
  background-color: white !important;
}


body{
  scroll-behavior: smooth;
}