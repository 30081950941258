@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?lwbozq');
  src:  url('../fonts/icomoon.eot?lwbozq#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?lwbozq') format('truetype'),
    url('../fonts/icomoon.woff?lwbozq') format('woff'),
    url('../fonts/icomoon.svg?lwbozq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-breadcrumbs-home-icon .path1:before {
  content: "\e900";
  color: rgb(119, 119, 119);
}
.icon-breadcrumbs-home-icon .path2:before {
  content: "\e901";
  margin-left: -1.0830078125em;
  color: rgb(0, 0, 0);
}
.icon-header-authorization-icon .path1:before {
  content: "\e902";
  color: rgb(255, 255, 255);
}
.icon-header-authorization-icon .path2:before {
  content: "\e903";
  margin-left: -0.8125em;
  color: rgb(0, 0, 0);
}
.icon-header-cart-icon .path1:before {
  content: "\e904";
  color: rgb(34, 34, 34);
}
.icon-header-cart-icon .path2:before {
  content: "\e905";
  margin-left: -1.099609375em;
  color: inherit;
}
.icon-header-location-icon .path1:before {
  content: "\e906";
  color: rgb(255, 255, 255);
}
.icon-header-location-icon .path2:before {
  content: "\e907";
  margin-left: -0.75em;
  color: inherit;
}
.icon-header-phone-icon:before {
  content: "\e908";
}
.icon-header-search-icon:before {
  content: "\e909";
}
.icon-news-card-arrow:before {
  content: "\e90a";
  color: #3b59a5;
}
.icon-product-order-button-icon:before {
  content: "\e90b";
  color: #fff;
}
.icon-product-slider-arrow:before {
  content: "\e90c";
  color: #888;
}
.icon-showcase-link-icon-2:before {
  content: "\e90d";
}
.icon-showcase-link-icon-3:before {
  content: "\e90e";
}
.icon-showcase-link-icon-4:before {
  content: "\e90f";
}
.icon-side-menu-arrow:before {
  content: "\e910";
}
