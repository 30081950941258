@import "font.less";


@lblue: #eff4f8;
@txt: #222;
@txt1: #555;
@blue: #3b59a5;
@grey: #c9d6dc;
@grey1: #555;
@grey2: #777;

.trans{
	-webkit-transition: 0.3s;
	   -moz-transition: 0.3s;
	    -ms-transition: 0.3s;
	     -o-transition: 0.3s;
	        transition: 0.3s;
}
*:focus, button:focus{
	outline: none;
}
.btn{
	background: #ccc;
	.icon{
		position: relative;
	}
}
.txt_grey{
	color: @txt1;
}
sup{
	top: 0;
}
.txt_lgrey{
	color: @grey1;
}
.txt_light{
	color: @grey2;
}
.txt_blue{
	color: @blue;
}
.border_dashed{
	border-bottom: 1px dashed @grey;
}
.form-group {
    margin-bottom: 1.4rem;
}
.size14{
	font-size: 14px;
}
img{
	max-width: 100%;
}
.bigger{
	font-size: 135%;
}
// .sbold{
// 	font-weight: 500;
// }
.text_sm{
	font-size: 13px;
	line-height: 1.3em;
}
.bold, strong{
	// font-weight: 600;
	.sbold;
}

.list_custom{
	padding: 0;
	list-style-type: none;
	li{
		margin-bottom: 10px;
	}
}
.list_styled{
	padding: 0;
	list-style-type: none;
	li{
		margin-bottom: 10px;
		position: relative;
		padding-left: 20px;
		&:before{
			content: "";
			position: absolute;
			left: 0;
			top: 6px;
			width: 6px;
			height: 6px;
			-webkit-border-radius: 100%;
			        border-radius: 100%;
			background: @grey;
		}
	}
}
// .list_custom1{
// 	li:not(:last-child){
// 		padding-right: 15px;
// 		position: relative;
// 		// &:after{
// 		// 	content: "";
// 		// 	position: absolute;
// 		// 	top: 0;
// 		// 	bottom: 0;
// 		// 	margin: auto;
// 		// 	right: 0;
// 		// 	width: 4px;
// 		// 	height: 4px;
// 		// 	background: @purp;
// 		// 	-webkit-border-radius: 100%;
// 		// 	        border-radius: 100%;
// 		// }
// 	}
// }
.list_inline{
	display: flex;
	padding-left: 0;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
	    list-style-type: none;
	li{
		margin: 5px;
	}
	li:first-child{
		margin-left: 0;
	}
	li:last-child{
		margin-right: 0;
	}
}
.social_network{
	width: 34px;
	height: 34px;
	display: block;
	display: flex;
	font-size: 18px;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	color: #FFF;
	background: @grey;
	-webkit-border-radius: 100%;
	        border-radius: 100%;
	&:hover{
		color: #fff;
		-webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
		        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
	}
}


.change_num{
	display: flex;
	.quantity-field{
		width: 60px;
	    height: 33px;
	    padding: 0;
	    background-color: #fff;
	    border-radius: 4px;
	    // font-weight: 300;
	    .light;
	    font-size: 1.1rem;
	    line-height: 27px;
	    text-align: center;
	    margin: 0 10px;
	    color: @grey1;
	    border: 1px solid @grey;
	}
	.button-minus,
	.button-plus {
	  display: block;
	    width: 35px;
	    height: 33px;
	    font-size: 1.4rem;
	    // font-weight: 100;
	    .light;
	    border: none;
	    border-radius: 4px;
	    outline: none;
	    cursor: pointer;
	    background: @lblue;
	    .trans;
	    &:hover{
	    	background: @grey;
	    }

	}


	input[type="number"] {
	  -moz-appearance: textfield;
	  -webkit-appearance: none;
	}
}
.order_form__wrap{
	h3{
		margin-bottom: 1.5rem;
	}
}
.order_form_col{
	display: inline-block;
	width: 100%;
	margin: 0.5rem 0;
}
.card_v2{
	// padding: 1rem;
	-webkit-border-radius: 2px;
	        border-radius: 2px;
	-webkit-box-shadow: -1px 1px 20px #d6dee1;
    box-shadow: -1px 1px 20px #d6dee1;
	    h3{
	    	margin-top: 5px;
	    }
}
.wrap{
	margin: 1rem 0;
	display: inline-block;
	width: 100%;
}
.authorization{
	display: flex;
	white-space: nowrap;
	.icon-header-authorization-icon{
		margin-right: 5px;
	}
}

.dropdown_scroll{
	max-height: 200px;
}




// @media screen and (min-width: 1300px){
// 	// .container{
// 	// 	width: 1440px;
// 	// }
// 	// .container-fluid{
// 	// 		.col-xl-3 {
// 	// 		    -ms-flex: 0 0 22%;
// 	// 		    flex: 0 0 22%;
// 	// 		    max-width: 22%;
// 	// 		}
// 	// 		.col-xl-9 {
// 	// 		    -ms-flex: 0 0 78%;
// 	// 		    flex: 0 0 78%;
// 	// 		    max-width: 78%;
// 	// 		}
// 	// }
// }
main{
	padding-bottom: 2rem;
}
.content{
	margin:2rem 0 2rem;
	h2{
		font-size: 1.1rem;
		margin: 2.4rem 0 1.5rem;
	}
	h3{
		font-size: 1.05rem;
		margin: 2rem 0 1.3rem;
	}
	p{
		line-height: 1.4em;
	}
	ul, ol{
		margin-bottom: 25px;
		li{
			margin-bottom: 10px;
		}
	}
}

body{
	font-family: Montserrat-Regular, Arial, sans-serif;
	font-size: 14px;
	color: @txt;
	font-weight: 400;
	line-height: 1.4em;
}
a{
	color: inherit;
	&:hover{
		color: @blue;
		text-decoration: none;
	}
}

.header_top, .lblue_bg{
	background: @lblue;
}
.page{
	padding-bottom: 438px;
	position: relative;
	min-height: 100vh;
}
footer{
	background-image: -webkit-gradient(linear, right top, left top, from(#fff), color-stop(70%, #f7fafc), to(#eff4f8));
    background-image: linear-gradient(-90deg, #fff 0%, #f7fafc 70%, #eff4f8 100%);


    position: absolute;
    bottom: 0;
    width: 100%;
    height: 438px;
    // line-height: 60px;
    background-color: #f5f5f5;

}

h1{
	font-size: 1.3rem;
	margin: 1rem 0 1.8rem;
	.med;
}
h2{
	font-size: 1.37rem;
	// font-weight: 500;
	.med;
	margin: 1rem 0;
}
.h2{
	font-size: 1.1rem;
	margin-bottom: 1.2rem;
}
h3{
	// font-weight: 600;
	.med;
	font-size: 1.1em;
	margin: 1rem 0;
}
h4{
	font-size: 1rem;
	margin: 1rem 0 1.5rem 0;
}
.title{
	font-size: 1em;
	.med;
	// font-weight: 500;
	margin: 0.8rem 0;
}
.link{
	.txt_blue;
	.trans;
}


.scroll_up{
	cursor: pointer;
	position: fixed;
	background: #FFF;
	bottom: 1.5rem;
	right: 1.5rem;
	z-index: 9;
	width: 49px;
	height: 49px;
	text-align: center;
	line-height: 49px;
	border: 1px solid @grey;
	-webkit-border-radius: 100%;
	        border-radius: 100%;
	-webkit-box-shadow: -1px 1px 20px #D6DEE1;
	        box-shadow: -1px 1px 20px #D6DEE1;
}
.showcase_link, .btn_custom1{
	background: #FFF;
	border: 1px solid @grey;
	height: 38px;
	width: 200px;
	display: inline-block;
	text-align: center;
	padding: 0 15px;
	line-height: 38px;
	white-space: nowrap;
	.trans;
	-webkit-box-shadow: -1px 1px 20px #D6DEE1;
	        box-shadow: -1px 1px 20px #D6DEE1;
	        &:hover{
	        	// color: #aa3939;
	        	background: #eff4f8;
	        }
}

.btn{
	-webkit-border-radius: 2px;
	        border-radius: 2px;
	            border: none;
}
.btn_custom{
	display: inline-block;
	.trans;
	-webkit-border-radius: 2px;
	        border-radius: 2px;
}
.btn_md{
	height: 40px;
	line-height: 40px;
	.med;
	font-size: 14px;
}
.btn_xmd{
	height: 44px;
	line-height: 44px;
	.med;
}
.btn_red{
	// .red_gr;
	padding: 0 25px;
	color: #FFF;
	// display: flex;
	// -webkit-align-items: center;
	//         align-items: center;
	//     -webkit-justify-content: center;
	//             justify-content: center;
	&:hover{
		// background: linear-gradient(-80deg,#aa3939 0,#c54646 100%);
		color: #FFF;
	}
}
.btn_grey{
	border: 1px solid @grey;
	background: @lblue;
	.med;
	padding: 0 35px;
	&:hover{
		background: #e1e6ea;
	}
}
.card_v1{
	background: #FFF;
	border: 1px solid @grey;
	-webkit-box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 5%);
	        box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 5%);
	-webkit-border-radius: 2px;
	        border-radius: 2px;
}


.modal{
	z-index: 10001;
	overflow: scroll;
	h3{
		font-size: 1.1rem;
	}
	.close{
		font-size: 3rem;
		line-height: 1.5rem;
		// font-family: Montserrat,sans-serif;
		.light;
		padding: 10px;
	}
}
.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover{
	// color: @purp;
	opacity: 1;
}
.modal_overlay{
  height: 100%;
  width: 100%;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  // opacity:0;
  // visibility:hidden;
  background-color:  rgba(27, 30, 30, 84%);
  transition:opacity .2s linear, visibility .1s, width 1s ease-in;
}
.modal-dialog{
	z-index: 2;
	margin-left: auto;
	margin-right: auto;
	pointer-events: auto;
}
.modal.show{
	display: block;

}

.breadcrumb{
	background: none;
	padding: 10px 0;
	-webkit-border-radius: 0;
	        border-radius: 0;
	margin-bottom: 2.2rem;
	border-top: 1px solid @grey;
	li{
		display: block;
	    padding-right: 18px;
	    padding-left: 25px;
	    font-size: 13px;
	    color: @txt;
	    position: relative;
	    &:before{

	    }
		a{
			color: #777;
			position: relative;
			.trans;
			&:hover{
				color: @txt;
			}
		}
	}
	li:first-child:before{
		font: normal normal normal 14px/1 FontAwesome;
		content: "\f015";
		position: absolute;
		top: 2px;
		left: 0;
		display: block;
		color: #777;
		font-size: 120%;

	}

}
.breadcrumb-item.active{
	color: @txt;
}
.breadcrumb-item+.breadcrumb-item::before{
	display: none;
}
.breadcrumb-item:after{
	font: normal normal normal 14px/1 FontAwesome;
	content: "\f105";
	color: #777;
	position: absolute;
	right: 2px;
	top: 3px;
	bottom: 0;

}
.breadcrumb-item:last-child:after{
		display: none;
	}

.checkbox_wrap{
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}
.checkbox_wrap{
	position: relative;
	padding-left: 30px;
	input{
		width: 20px;
		position: absolute;
		left: 0;
		top: 0;
		height: 20px;
		opacity: 0;
		z-index: 1;
	}
	.checkmark {
		width: 20px;
	height: 20px;
	border: 1px solid ;
	position: relative;
	  position: absolute;
	  top: 1px;
	  left: 0;
	  // height: 100%;
	  // width: 100%;
	  background-color: transparent;
	}
	.checkmark:after {
	  content: "";
	  position: absolute;
	  display: none;
	}
	input:checked ~ .checkmark:after {
	  display: block;
	}


	.checkmark:after {
  left: 6px;
  top: 1px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
}

.checkbox_color{
	position: relative;
	margin: 3px;
	input{
		opacity: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		cursor: pointer;
		z-index: 2;
	}
	label{
		margin: 0;
		.trans;
		width: 25px;
		height: 25px;
		background: #f1f1f1;
		border: 1px solid transparent;
		-webkit-border-radius: 2px;
		        border-radius: 2px;
		position: relative;
		&:after{
			content: "";
			opacity: 0;
			position: absolute;
			width: 9px;
		    height: 6px;
		    margin: 7px 0 0 7px;
		    border-bottom: 2px solid #fff;
		    border-left: 2px solid #fff;
		    -webkit-transform: rotate(-45deg);
		       -moz-transform: rotate(-45deg);
		        -ms-transform: rotate(-45deg);
		         -o-transform: rotate(-45deg);
		            transform: rotate(-45deg);
		}
	}
	input:checked + label,
	input:hover + label{
		border-color: @txt;
	}
	input:checked + label{
		&:after{
			opacity: 1;
		}
	}
}
label{
	sup.bigger{
		top: -0.1em;
	}
}


.jq-checkbox{
	width: 22px;
	min-width: 22px;
	height: 22px;
	border-color: @grey;
	background: #FFF;
	margin-right: 10px;
	-webkit-box-shadow: none;
		        box-shadow: none;
}
.jq-checkbox.checked .jq-checkbox__div {
    width: 9px;
    height: 6px;
    margin: 6px 0 0 6px;
    // border-bottom: 2px solid @purp;
    // border-left: 2px solid @purp;
}
.view_type{
	margin: 5px;
	.trans;
	&.active, &:hover{
		// color: @purp;
	}
}
.dropdown_v1{
	-webkit-border-radius: 2px;
	        border-radius: 2px;

	.dropdown-item{
		font-size: 13px;
	}
}
.dropdown__title{
	display: inline-block;
    padding-right: 32px;
    cursor: pointer;
    position: relative;
    &:before{
    	content: '';
	    display: block;
	    width: 11px;
	    height: 15px;
	    font-size: 15px;
	    font: normal normal normal 14px/1 FontAwesome;
    	content: "\f107";
	    position: absolute;
	    right: 0;
	    top: 50%;
	    -webkit-transform: translate(0, -50%);
	    transform: translate(0, -50%);
    }
}
.dropdown.show{
	.dropdown__title{
		&:before{
			font: normal normal normal 14px/1 FontAwesome;
    		content: "\f106";
		}
	}
}
.catalog_filter_col{
	.dropdown-menu{
		margin-top: 0.9rem;
		background: rgba(255,255,255,0.98);
		.dropdown-item{
			padding: 0.5rem 1rem;
		}
	}
}
.contact_phone{
	font-size: 22px;
	// color: @purp;
	display: block;
	.trans;
	margin-bottom: 5px;
	.bigger{
		font-size: 27px;
	}
}
.contact_time{
	.txt_lgrey;
}
// .form-group{
// 	position: relative;
// }
// .error_notification{
// 	position: absolute;
// 	bottom: -20px;
// 	left: 0;
// 	color: @purp;
// 	font-size: 12px;
// }
.form-control{
	border: 1px solid @grey;
    border-radius: 2px;
    font-size: 13px;
    padding: 5px 15px;
    &:focus{
    	border-color: @grey - #222;
    	outline: none;
    	-webkit-box-shadow: none;
    	        box-shadow: none;
    	-webkit-box-shadow: 2px 4px 30px 0 rgba(55,55,55,.1);
	        box-shadow: 2px 4px 30px 0 rgba(55,55,55,.1);
    }
}
.form-control.dark{
	background: @lblue;
}
.form-control.addition{
	border: 1px dashed @grey;
}
input.form-control{
	height: 44px;

}
.list_ThreeCol{
	-webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
	    list-style-type: none;
	    padding: 0;
	    margin: 0;
	    li{
	    	width: 100%;
	    	display: block;
	    	margin-bottom: 10px;
	    }
}
.list_TwoCol{
	-webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
	    list-style-type: none;
	    padding: 0;
	    margin: 0;
	    li{
	    	width: 100%;
	    	display: block;
	    	margin-bottom: 10px;
	    }
}
.list_TwoCol1{
		display: inline-block;
		width: 100%;
	padding: 0;
	margin: 0;
	list-style-type: none;
	margin-left: -2rem;
	li{
		float: left;
		width: 50%;
		padding-left: 2rem;

	}
}
.ul_dt{
	margin-bottom: 10px;
	margin-left: 0;
	li{
		display: flex;
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
		    margin: 8px 0;
		    padding-left: 0;
		    .dt{
		    	margin-right: auto;
		    	max-width: 60%;

		    }
		    .dd{
		    	max-width: 40%;
		    	text-align: right;
		    	color: @grey2;
		    	padding: 0 10px;
		    }
	}
}
// .striped_block{
// 	.striped_block__cell{
// 		padding: 8px 10px;
// 	}
// 	.striped_block__cell:nth-child(odd) {
// 		background: @lblue;
// 	}
// 	.striped_block__cell:nth-child(even) {
// 		background: #f1f1f1;

// 	}
// }
.search_form{
	position: relative;
	-webkit-flex-grow: 1;
	        flex-grow: 1;
	.form-control{
		padding-right: 50px;
	}
	.search_btn{
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		background: none;
		border: none;
		width: 50px;
	}
}
.subscribe_form{
	position: relative;
	.form-control{
		font-size: 15px;
	}
	.btn{
		position: absolute;
		right: 0;
		top: 0;
	}
}
.logo{
	width: 220px;
	min-width: 220px;
}
.header_cart{
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}
.header_cart__icon{
	width: 51px;
    height: 50px;
    position: relative;
    display: flex;
    font-size: 1.2rem;
    padding-left: 5px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background: url(../images/cart_bg.svg) no-repeat;
}
.header_cart__count{
	position: absolute;
	width: 16px;
	height: 16px;
	text-align: center;
	line-height: 16px;
	padding: 0;
	-webkit-border-radius: 100%;
	        border-radius: 100%;
	top: 10px;
	left: 10px;
	// .red_gr;
	color: #FFF;
	font-size: 11px;
	.med;
}
.header_cart__txt{
	.sbold;
}
.navbar-toggler{
	padding: 0;
	span{
		width: 30px;
		height: 3px;
		display: block;
		margin: 5px 0;
		background: @txt;
	}
}
.offcanvas-header{ display:none; }
.screen-overlay {
  height: 100%;
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  opacity:0;
  visibility:hidden;
  background-color:  rgba(27, 27, 27, 85%);
  transition:opacity .2s linear, visibility .1s, width 1s ease-in;
}
.screen-overlay.show {
  transition:opacity .5s ease, width 0s;
  opacity:1;
  width:100%;
  visibility:visible;
}



.item_label{
	height: 26px;
	// .red_gr;
	// min-width: 105px;
	color: #FFF;
	position: relative;
	display: inline-block;
	line-height: 26px;
	padding: 0 20px 0 15px;
	text-align: center;
	// background: url(../images/product-discount.svg) no-repeat;
	// -webkit-background-size: 100% 100%;
	//      -o-background-size: 100% 100%;
	//         background-size: 100% 100%;
	&.new{
		background: url(../images/product-discount.svg) no-repeat;
		-webkit-background-size: 100% 100%;
	     -o-background-size: 100% 100%;
	        background-size: 100% 100%;
	}
	&.hit{
		background: url(../images/product-hit-2.svg) no-repeat;
		-webkit-background-size: 100% 100%;
	     -o-background-size: 100% 100%;
	        background-size: 100% 100%;
	}

}

.category_list{
	display: flex;
	padding: 0;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
	    list-style-type: none;
	li{
		margin: 0px 15px 3px 0;
	}
}
.catalog_filter{
	padding: 10px 15px;
	background: @lblue;
	display: flex;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}
.category_label{
	color: @grey2;
	white-space: nowrap;
	strong{

		margin-left: 5px;
		// color: @purp;
	}
}


.side_menu{
	ul{
		list-style-type: none;
		margin: 0;
		padding: 0;

	     border: 1px solid #c9d6dc;
	    border-radius: 2px;
	    -webkit-box-shadow: 2px 5px 28px 0px rgba(0, 0, 0, 0.1);
	    box-shadow: 2px 5px 28px 0px rgba(0, 0, 0, 0.1);
	}
	li{
		position: relative;
		background-color: #fff;
    	border: 1px solid #c9d6dc;
		border-left:none;
    	border-bottom: none;
    	.trans;
    	.icon{
    		position: absolute;
    		left: 18px;
    		top: 10px;
    		width: 15px;
    	}
    	&:last-child{
    		border-bottom: 1px solid #c9d6dc;
    	}
		a{
			display: block;
			padding: 12px 25px;
		}
		.side_menu__link{
			padding: 12px 45px;
			.med;
		}
		&:hover{
			background: @lblue;
			&>a{
				// color: @purp;
			}
		}
	}
}


 .side_menu__sub {
    display: none;
    width: 245px;
    position: absolute;
    left: calc(100% );
    top: -1px;
    z-index: 10001;
    padding-left: 5px !important;

}




.old_price{
	opacity: 0.7;
	font-size: 13px;
	white-space: nowrap;
	text-decoration: line-through;
}
.current_price{
	.med;
	white-space: nowrap;
	font-size: 1.1em; //15px
	.bigger{
		.sbold;
		font-size: 135%;
	}
}
.item_card{
	background: transparent;
	text-align: center;
	position: relative;
	padding: 1rem;
	margin-left: auto;
	margin-right: auto;
	max-width: 300px;
	-webkit-border-radius: 2px;
	        border-radius: 2px;
	.trans;



	.item_labels{
		position: absolute;
		top: 0.5rem;
		left: -0.5rem;
		display: flex;
		z-index: 1;
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
		.item_label{
			margin: 5px 5px 5px 0;
		}
		.item_label:last-child{
			margin-right:0;
		}
	}

	.title{
		height: 54px;
		overflow: hidden;
	}

}

.item_card__bottom .btn{
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	    -webkit-justify-content: center;
	            justify-content: center;
}
.item_card__pic{
    margin: auto;
    // height: 150px;

    height: 145px;
    position: relative;
    img {
	    max-height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    bottom: 0;
	    right: 0;
	    margin: auto;
	}
}

.elements_card{
	padding-top: 1rem;
	// display: flex;
	// padding-right: 15px;
	h3{
		font-size: 0.9rem;
		margin: 5px 0 10px 0;
	}
}
.elements_card__pic{
	// -ms-flex-negative: 0;
 //    flex-shrink: 0;
 //    margin-right: 20px;
    height: 165px;
    display: inline-block;
    clear: both;
    border: 1px solid @lblue;
    margin-bottom: 1rem;
    img{
    	max-height: 100%;
    }
}
.elements_card{
	position: relative;
	padding-bottom: 90px;
	.bottom{
		position: absolute;
		bottom: 0;
		left: 0;
	}
	ul{
		li{
			margin-bottom: 5px;
		}
	}
}
.slick-dotted.slick-slider{
	margin-bottom: 0;
}
.slick-dots{

	li{
		width: auto;
		height: auto;
		button{
			.trans;
			width: 10px;
			height: 10px;
			background: transparent;
			border: 1px solid @grey;
			-webkit-border-radius: 100%;
			        border-radius: 100%;
			&:before{
				display: none;
			}
		}
		&.slick-active, &:hover{
			// button{
			// 	background: @purp;
			// 	border-color: @purp;
			// }
		}
	}
}



.showcase_slider{
	.slick-dots{
		bottom: 20px;
	}
}
.slider_v1{
	position: relative;
	padding-top: 2.5rem;
	z-index: 1;
	.slick-list {
	    margin: 0 -1rem;
	}

	.slick-dots{
		width: auto;
		right: 7rem;
		left: auto;
		top: 0;
		bottom: auto;
	}

	.slick-arrow{
		z-index: 1;
		width: 34px;
		height: 34px;
		border: 1px solid @grey;
		-webkit-border-radius: 2px;
		        border-radius: 2px;
		left: auto;
		right: 0;
		bottom: auto;
		top: 0;
		.trans;
		color: @txt;
		background: transparent;
		-webkit-transform: none;
			   -moz-transform: none;
			    -ms-transform: none;
			     -o-transform: none;
			        transform: none;
		&:before{
			font: normal normal normal 20px/1 FontAwesome;
			line-height: 17px;
			content: "\e90c";
			color:@txt;
		}
		// &:hover{
		// 	border-color: @purp;
		// 	&:before{
		// 		color: @purp;
		// 	}
		// }
	}
	.slick-prev{
		right: 3rem;
		&:before{
			content: "\f104";
		}
	}
	.slick-next{
		&:before{
			content: "\f105";
		}
	}
}
.ellipsis {
    overflow: hidden;
}
.cards_slider, .cards_slider__sm{
	.slick-slide {
	    opacity: 0;
	    -webkit-transition: opacity 0.1s;
	    transition: opacity 0.1s;
	}
	.slick-slide.slick-active{
		opacity: 1;

	}
}
.noUi-target{
	height: 10px;
}
// .noUi-connect{
// 	background: @purp;
// }
.noUi-horizontal .noUi-handle{
	width: 20px;
	height: 20px;
	-webkit-border-radius: 100%;
	        border-radius: 100%;
}
.noUi-handle:after, .noUi-handle:before{
	display: none;
}
.noUi-touch-area:focus{
	outline: none;
}


.cards_slider, .cards_slider__sm{
	margin-bottom: 0 !important;



}
.news_slider, .elements_slider{
	.elements_card{
		height: 100%;
	}
	.slick-track
	{
	    display: flex !important;
	}

	.slick-slide
	{
	    height: inherit !important;
	}
}

.news_slider{
	.news_card{
		height: 100%;
		max-width: 400px;
		margin: 0 auto;

	}
}
.news_card{
	.txt_lgrey;
	position: relative;
	padding: 1rem 1rem 2.5rem 1rem;
	.news_card__bottom{
		position: absolute;
		left: 0;
		width: 100%;
		bottom: 0;
		padding: 1rem;
	}
}
.news_card__pic{
	width: 100%;
	height: 180px;
    position: relative;
    img{
    	position: absolute;
    	max-width: 100%;
    	max-height: 100%;
    }
}
.news_card__text{
	p{
		margin-bottom: 0;
	}
}


.specifications{
	list-style-type: none;
	display: inline-block;
	width: 100%;
	// display: -webkit-box;
 //    display: -ms-flexbox;
 //    display: flex;
 //    -ms-flex-wrap: wrap;
 //    flex-wrap: wrap;
    // margin: 0 -15px;
    padding: 0;
}
.specifications li {
	float: left;
	width: 50%;
	margin: 10px 0;
    // display: -webkit-box;
    // display: -ms-flexbox;
    // display: flex;
    // width: 50%;
    // margin: 0 15px 16px;
}
.specifications_item{
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    width: 100%;
}
.specifications_name{
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    .med;
}
.specifications_separator{
	-webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(33%, @grey), color-stop(0%, transparent));
    background-image: linear-gradient(to right, @grey 33%, transparent 0%);
    background-size: 6px 2px;
    background-repeat: repeat-x;
    background-position: left bottom 2px;
    margin: 0 15px;
}
.specifications_value{
	-webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 50%;
    color: @grey1;
    .item{
    	position: relative;

    }
    .item+.item{
    	margin-left: 10px;
    	padding-right: 10px;
    	&:before{
    		position: absolute;
    		content: "/";
    		top: 0;
    		left: -7px;
    	}
    }
}



.product_details{
	h1{
		margin: 0.5rem 0 1.5rem;
	}
	.old_price{
		font-size: 1rem;
	}
	.current_price{
		font-size: 1.3em;
	}
}

.nav-tabs.tabs_custom1{
	border-bottom: 1px solid @grey;
	li{
		margin-bottom: 0;
	}
	.nav-link{
		display: block;
	    padding: 17px 24px;
	    background-color: @lblue;
	    border: 1px solid @grey;
	    border-left: none;
	    border-bottom: none;
	    .med;
	    -webkit-border-radius: 2px;
	            border-radius: 2px;
	    .trans;
	    &:hover{
	    	background: #FFF;
	    	color: initial;
	    }
	    &.active{
	    	background: #FFF;
	    	webkit-box-shadow: 2px 4px 30px 0 rgba(55,55,55,.1);
	        box-shadow: 2px 4px 30px 0 rgba(55,55,55,.1);
	    }
	}
	li:first-child .nav-link {
	    border-left: 1px solid @grey;
	    border-top-left-radius: 2px;
	}
}
.nav-tabs.tabs_custom2{
	border-bottom: none;
	.nav-item{
		.nav-link{
			min-width: 180px;
			padding: 17px 30px;
			.med;
			border: 1px solid transparent;
			.trans;
			text-align: center;
			-webkit-border-radius: 0;
			        border-radius: 0;
		}
		.underline{
			border-bottom: 1px dashed @grey;
		}
	}

}
.nav-tabs.tabs_custom2 .nav-item.show .nav-link, .nav-tabs.tabs_custom2 .nav-link.active{
	border-color: @grey;
	webkit-box-shadow: 2px 4px 30px 0 rgba(55,55,55,.1);
	        box-shadow: 2px 4px 30px 0 rgba(55,55,55,.1);
	        .underline{
	        	border-bottom: none;
	        }
}

.mobile_tabscollapse .nav-tabs{
    display:none;
}
.mobile_tabscollapse{
	.collapse_trigger{
		width: 100%;
		border: none;
		text-align: left;
		background: #fff;
		position: relative;
		&:after{
			font: normal normal normal 14px/1 FontAwesome;
			content: "\f106";
			position: absolute;
			right: 15px;
			top: 0;
			bottom: 0;
			height: 18px;
			font-size: 18px;
			margin: auto;
		}
		&.collapsed:after{
			font: normal normal normal 14px/1 FontAwesome;
			content: "\f107";
			font-size: 18px;
		}
	}
}

.collapse_wrap__item{
	.collapse_link{
		display: block;
		padding: 8px 0;
		position: relative;
		&:after{
			font: normal normal normal 14px/1 FontAwesome;

			content: "\f107";
			font-size: 18px;
			position: absolute;
			top: 10px;
			right: 0;
		}
		&.opened:after{
			font: normal normal normal 14px/1 FontAwesome;
			content: "\f106";
		}
	}
}
.params_list{
	max-height: 190px;
	position: relative;
	padding-right: 15px;
   overflow: auto;
   margin-top: 5px;
}
.ps__rail-y {
    display: none;
    width: 4px;
    background-color: @lblue;
    opacity: 1;
    transition: background-color .2s linear, opacity .2s linear;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    right: 0;
    position: absolute;
}
.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y{
	 background-color: @lblue;
}
.ps__thumb-y {
    border-radius: 6px;
    width: 4px ;
    // background-color: @purp ;
    border-radius: 0;
    right: 0;
    position: absolute;
}
.rangeInput_wrap{
	position: relative;
	.sliderValue{
		padding-left: 40px;
		font-size: 14.5px;
		padding-right: 5px;
		-ms-text-overflow: ellipsis;
		    text-overflow: ellipsis;
	}
}
.rangeInput_label{
	position: absolute;
	top: 14px;
	left: 10px;
	font-size: 12px;
	color: @grey2;

}
.range__point{
	color: @grey2;
	font-size: 13px;
	position: relative;
	&:after{
		content: '';
	    display: block;
	    width: 1px;
	    height: 5px;
	    background-color: @grey2;
	    position: absolute;
	    top: calc(100% + 2px);
	    left: 50%;
	    -webkit-transform: translate(-50%, 0);
	    transform: translate(-50%, 0);
	}
}

.error_block{
	display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.error_block_zzz{
	position: relative;
	max-width: 415px;
}
.zzz{
	display: block;
    width: 16px;
    height: 60px;
    position: absolute;
    left: 37.2%;
    top: 34.5%;
}
@-webkit-keyframes zzzAnimation {
	0% {
		-webkit-transform: translate3d(0px, 0px, 0px);
		transform: translate3d(0px, 0px, 0px);
		opacity: 0
	}
	50% {
		-webkit-transform: translate3d(0px, -5px, 0px);
		transform: translate3d(0px, -5px, 0px);
		opacity: 1
	}
	100% {
		-webkit-transform: translate3d(0px, -10px, 0px);
		transform: translate3d(0px, -10px, 0px);
		opacity: 0
	}
}

@keyframes zzzAnimation {
	0% {
		-webkit-transform: translate3d(0px, 0px, 0px);
		transform: translate3d(0px, 0px, 0px);
		opacity: 0
	}
	50% {
		-webkit-transform: translate3d(0px, -5px, 0px);
		transform: translate3d(0px, -5px, 0px);
		opacity: 1
	}
	100% {
		-webkit-transform: translate3d(0px, -10px, 0px);
		transform: translate3d(0px, -10px, 0px);
		opacity: 0
	}
}
.zzz__1 {
    position: absolute;
    left: 0;
    bottom: 0;
    -webkit-animation: zzzAnimation 2s linear infinite;
    animation: zzzAnimation 2s linear infinite;
}
.zzz__2 {
    position: absolute;
    right: 2px;
    bottom: 17px;
    -webkit-animation: zzzAnimation 2s linear infinite;
    animation: zzzAnimation 2s linear infinite;
}
.zzz__3 {
    position: absolute;
    left: 0;
    bottom: 33px;
    -webkit-animation: zzzAnimation 2s linear infinite;
    animation: zzzAnimation 2s linear infinite;
}
.zzz__4 {
    position: absolute;
    right: 0;
    bottom: 49px;
    -webkit-animation: zzzAnimation 2s linear infinite;
    animation: zzzAnimation 2s linear infinite;
}
.zzz__5 {
    position: absolute;
    left: 1px;
    bottom: 55px;
    -webkit-animation: zzzAnimation 2s linear infinite;
    animation: zzzAnimation 2s linear infinite;
}
.error_wrap{
	background: url(../images/error_bg.png) no-repeat;
	background-position: top center;
	-webkit-background-size: cover;
	     -o-background-size: cover;
	        background-size: cover;
	color: #FFF;
	height: 720px;

	h1{
		font-size: 30px;
		.sbold;
	}
}
.article_card{
	display: flex;
	p{
		margin-bottom: 8px;
	}
}
.article_card__date{
	font-size: 13px;
	color: @grey2;
}
.article_card__pic{
	width: 300px;
	min-width: 300px;
	margin-right: 1rem;

}
.article_card__info{
	max-width: 450px;
	h3{
		margin: 0 0 10px 0;
	}
}

.cart_list{
	.cart_item{
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		position: relative;
		border-bottom: 1px solid @grey;
		padding: 0.8rem 0;
		h3{
			font-size: 0.95rem;
		}
	}
}
.cart_item__main{
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	    margin-right: auto;
}
.cart_item__details{
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}
.list_full {
	.cart_item__content{
		// max-width: 60%;
	}
	h3{
		margin: 5px 0;
	}
	.item_price{
		// min-width: 135px;
		// margin-left: 1rem;
		font-size: 0.75rem;
	}
	.price_wrap{
		min-width: 135px;

	}
	.current_price{
		.reg;
		font-size: 1em;
		.bigger{
			.med;
		}
	}
}

.promo_input{
	position: relative;
	padding-right: 136px;
	width: 340px;
	.btn{
		position: absolute;
		right: 0;
		top: 0;
		font-size: 14px;
		padding-left: 20px;
		padding-right: 20px;
	}

}
.cart_item__pic{
	width: 130px;
	min-width: 130px;
	max-width: 130px;
}
.cart_item__remove{
	min-width: 35px;
	font-size: 30px;
	// color: @purp;
	text-align: right;
}
.cart_total{
	padding: 1rem 0;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	    -webkit-justify-content: space-between;
	            justify-content: space-between;

}




.modal-dialog.modal-xl {
    max-width: 1000px;
    width: 90%;
  }
.modal-content{
	-webkit-border-radius: 2px;
	        border-radius: 2px;
        &>.close{
        	position: absolute;
        	top: 5px;
        	right: 5px;
        	z-index: 2;
        }
}
.side_filter__head .close{
	display: none;
}
.showcase_links{
	display: flex;
	padding: 0;
	margin: 0 -15px;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
	    list-style-type: none;
	    -webkit-justify-content: space-between;
	            justify-content: space-between;
	    // li{

	    // 	margin-left: 15px;
	    // 	margin-right: 15px;
	    // }

	    .showcase_link{
	    	width: 100%;
	    	margin: 5px 0;
	    	display: flex;
	    	-webkit-justify-content: center;
	    	        justify-content: center;
	    	-webkit-align-items: center;
	    	        align-items: center;
	    	.icon{
	    		width: 15px;
	    		display: inline-block;
	    	}
	    }
}




.side_filter{
	h3{
		font-size: 16px;
	}
	.collapse_content{
		display: none;
	}
}
.filterModal{
}

.pagination{
	border-top: 1px solid @grey;
	padding: 2rem 0;
	margin: 1rem 0;
	-webkit-border-radius: 0;
	        border-radius: 0;
	.page-item{
		.page-link{
			color: @txt;
			display: block;
			min-width: 35px;
			height: 35px;
			display: flex;
			-webkit-border-radius: 5px;
			        border-radius: 5px;
			-webkit-align-items: center;
			        align-items: center;
			-webkit-justify-content: center;
			        justify-content: center;
			border: 1px solid transparent;
			&:hover{
				background: transparent;
				// color: @purp;
			}
			&.current{
				background: @lblue;
				border-color: @lblue;
				.sbold;
			}
		}

	}
	.page-item.disabled{
		opacity: 0.3;
	}
	.arrow_item{
		margin: 0 1.2rem;
		font-size: 1.3rem;

		.page-link{
			color: @grey2;
			border-color: @grey;
		}
	}
}
// .article_card__text{
// 	height: 100px;
// 	overflow: hidden;

// }
.article_card__info{
	position: relative;
	padding-bottom: 1.5rem;
	.bottom{
		position: absolute;
		left: 0;
		bottom: 0;
	}
}











/*----------------------------
    The file upload form
-----------------------------*/


#upload{
    font-family:'PT Sans Narrow', sans-serif;
    background-color:#373a3d;

    background-image:-webkit-linear-gradient(top, #373a3d, #313437);
    background-image:-moz-linear-gradient(top, #373a3d, #313437);
    background-image:linear-gradient(top, #373a3d, #313437);

    width:250px;
    padding:30px;
    border-radius:3px;

    margin:200px auto 100px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

#drop{
    background-color: #2E3134;
    padding: 40px 50px;
    margin-bottom: 30px;
    border: 20px solid rgba(0, 0, 0, 0);
    border-radius: 3px;
    border-image: url('../images/border-image.png') 25 repeat;
    text-align: center;
    text-transform: uppercase;

    font-size:16px;
    .med;
    color:#7f858a;
}

#drop a{
    background-color:#007a96;
    padding:12px 26px;
    color:#fff;
    font-size:14px;
    border-radius:2px;
    cursor:pointer;
    display:inline-block;
    margin-top:12px;
    line-height:1;
}

#drop a:hover{
    background-color:#0986a3;
}

#drop input{
    display:none;
}

#upload ul{
    list-style:none;
    margin:0 -30px;
    border-top:1px solid #2b2e31;
    border-bottom:1px solid #3d4043;
}

#upload ul li{

    background-color:#333639;

    background-image:-webkit-linear-gradient(top, #333639, #303335);
    background-image:-moz-linear-gradient(top, #333639, #303335);
    background-image:linear-gradient(top, #333639, #303335);

    border-top:1px solid #3d4043;
    border-bottom:1px solid #2b2e31;
    padding:15px;
    height: 52px;

    position: relative;
}

#upload ul li input{
    display: none;
}

#upload ul li p{
    width: 144px;
    overflow: hidden;
    white-space: nowrap;
    color: #EEE;
    font-size: 16px;
    .med;
    position: absolute;
    top: 20px;
    left: 100px;
}

#upload ul li i{
    .reg;
    font-style:normal;
    color:#7f7f7f;
    display:block;
}

#upload ul li canvas{
    top: 15px;
    left: 32px;
    position: absolute;
}

#upload ul li span{
    width: 15px;
    height: 12px;
    background: url('../images/icons.png') no-repeat;
    position: absolute;
    top: 34px;
    right: 33px;
    cursor:pointer;
}

#upload ul li.working span{
    height: 16px;
    background-position: 0 -12px;
}

#upload ul li.error p{
    color:red;
}


.slider-for{
	margin-bottom: 1rem;
	.slick-slide {
		height: 300px;
		img{
			max-height: 100%;
			margin: auto;
		}
	}
}
.slider-nav{
	// padding: 0 10px;
	.slick-slide{
		margin: 0 8px;
	}
	.slick-list {
	    margin: 0 -8px;
	}
}
.sl-overlay{
	background: #000;
}
.total_sum{
	font-size: 18px;
	.bigger{
		font-size: 26px;
		.sbold;
	}
}
.total_title{
	margin: 5px 0.7rem 5px 0;
	font-size: 16px;
}
.radio_card{
	margin-bottom: 1rem;
	position: relative;
	input[type="radio"]{
		opacity: 0;
		width: 100%;
		left: 0;
		top: 0;
		height: 100%;
		cursor: pointer;
		position: absolute;
	}
	input[type=radio]:checked + .radio_card__content{
		background: #FFF;
		border-color: @grey;
		-webkit-box-shadow: -1px 1px 20px #d6dee1;
    	box-shadow: -1px 1px 20px #d6dee1;

	}
}
.radio_card__content{
	background: @lblue;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	padding: 0.8rem 1rem;
	height: 100%;
	font-size: 13px;
	line-height: 1.2em;
	color: @grey2;
	border: 1px solid @lblue;
	-webkit-border-radius: 2px;
	        border-radius: 2px;
	-webkit-border-radius: 1px solid @lblue;
	        border-radius: 1px solid @lblue;
	h3{
		margin: 0 0 5px 0;
		font-size: 14px;
		color: @txt;
	}
	p{
		margin-bottom: 0;
	}
}
.radio_card__icon{
	width: 61px;
	min-width: 61px;
	margin-right: 10px;
}
.radio_type{
	position: relative;
	text-align: center;
	input[type="radio"]{
		display: block;
		margin: auto;
	}
	// input[type="radio"]{
	// 	position: absolute;
	// 	width: 100%;
	// 	height: 100%;
	// 	top: 0;
	// 	left: 0;
	// }
	// .jq-radio{
	// 	position: static;
	// 	margin: auto;
	// 	display: block;
	// }

}
// .radio_type__pic{
// 	display: block;
// 	height: auto;
// 	margin: auto;

// }
// .jq-radio.checked .jq-radio__div{
// 	margin: 2px 0 0 2px;
// }






aks-file-upload {
  width: 100%;
  display: block;
  margin: 1rem auto 0;
}
.aks-file-upload{
	background: @lblue;
	-webkit-border-radius: 2px;
	        border-radius: 2px;
	-webkit-box-shadow: none;
	        box-shadow: none;
	    border-color: @grey;
}
.aks-file-upload-content{
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
	        -webkit-align-items: flex-start;
	                align-items: flex-start;
}
.aks-file-upload-label{
	margin-bottom: 0;
	flex-shrink: 0;
}
.aks-file-upload-preview{
	max-width: 218px;
	width: 31%;
	// float: left;
	margin: 5px;
}
// #uploadfile{
//     width: 80%;
//     margin: 0 auto;
//     color: #002c7b;
//     line-height:1.5;
//     margin-top: 2rem;
//     margin-bottom: 2rem;
// }
.order_form__wrap{
	label{
		margin-bottom: 10px;
	}
}
.order_form__content{
	max-width: 800px;
	padding: 1rem 0;

}
.order_form__total{
	border-top: 1px solid @grey;
	padding: 1.5rem 0;
	margin: 1rem 0;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	    -webkit-justify-content: space-between;
	            justify-content: space-between;
	.total_title{
		font-size: 18px;
	}
}
.order_form__comment{
	width: 32%;
	margin-right: auto;
}
.order_form__confirm{
	max-width: 258px;
}
.map_wrap{
	height: 400px;

}

.order_table, .content table {
	font-size: 13px;
	width: 100%;
	border: 1px solid @grey;
	thead tr{
		background: @lblue;
	}
	td:not(:last-child){
		border-right: 1px solid @grey;
	}

}
.content table {
	td{
		padding: 8px;
	}
}

.order_table__date,
.order_table__amount,
.order_table__total,
.order_table__payment,
.order_table thead th{
	white-space: nowrap;
}

.user_email{
	max-width: 215px;
	overflow: hidden;
	-ms-text-overflow: ellipsis;
	    text-overflow: ellipsis;
}
.header_location__name{
	max-width: 215px;
	overflow: hidden;
	display: block;
	-ms-text-overflow: ellipsis;
	    text-overflow: ellipsis;
}






@media (min-width: 992px){

	.header_location,
	header .authorization{
		flex: 1;
	    display: flex;
	    min-width: -webkit-min-content;

	}
	header .authorization{
		 justify-content: flex-end;
	}



	.modal-lg {
	    max-width: 770px;
	}
	.ul_dt li{
		padding-right: 1rem;
	}
	.side_menu li:hover >.side_menu__sub {
	    display: block;
	}
	.side_menu__sub .side_menu__item .side_menu__link{
    	padding-left: 25px;
    }

    .item_card__bottom{
		// display: none;
		opacity: 0;
		.trans;
	}
    .item_card{
    	&:hover{
			background: #FFF;
			z-index: 1;
			-webkit-box-shadow: 2px 5px 40px 0 rgba(0,0,0,.15);
		        box-shadow: 2px 5px 40px 0 rgba(0,0,0,.15);
			    .item_card__bottom{
			    	opacity: 1;
			    	// display: block;
			    }
		}
    }
    .item_card__inner{
		position: relative;
		margin-top: -2rem;
	}
	.catalog_list{
		padding-top: 3.5rem;
		margin-bottom: -1rem;
		.item_card{
			margin-top: -1rem;
		}

	}
	section{
		.cards_slider{
			.slick-list{
				margin: -3rem -3.7rem -8rem -3.7rem;
			}
		}
	}
	.slider_v1{
		margin-top: -1rem;
		.item_card{
			margin-top: 2rem;
		}
	}
    .cards_slider, .cards_slider__sm{
		.slick-list{
			padding: 3rem;
			margin: -3rem -3.7rem -4rem -3.7rem;
		}

	}
	.filter_trigger__wrap{
		display: none;
	}


	.mobile_dropdown{
		.dropdown__title{
			display: none;
		}
		.dropdown-menu{
			padding: 0;
			font-size: 14px;
			border: none;
			display: block;
			position: static;
			-webkit-transform: none;
			   -moz-transform: none;
			    -ms-transform: none;
			     -o-transform: none;
			        transform: none;
		}

	}
}
@media(min-width:1201px) {


}

@media(min-width:992px) {

.filterModal{
	width: 240px;
	position: static;
	opacity: 1;
	display: block;
	overflow: visible;
	z-index: 0;
	.modal_overlay{
		 display: none !important;
	}
	.modal-dialog{
		pointer-events: auto;
	}


}
.elements_slider{
		display: flex;
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
		    padding-top: 0;
		    margin-left: -15px;
		    margin-right: -15px;
		.elements_col{
			width: 33.3%;
			padding: 15px;
			margin-top: 15px;
			margin-bottom: 15px;
			align-items: stretch
		}
		.elements_card{
			height: 100%;

		}
	}



    .mobile_tabscollapse .nav-tabs{
        display: flex;
    }




    .mobile_tabscollapse .card-header {
        display:none;
    }

    .mobile_tabscollapse .tab-pane > .collapse{
        display:block;
    }

}




@media screen and (max-width: 1200px){

	footer{
		height: 505px;
	}
	.page{
		padding-bottom: 505px;
	}
	.order_table {
		display: block;
		width: 100%;
		thead{
			display: none;
		}
		tbody{
			display: block;
			width: 100%;
			tr{
				display: flex;
				width: 100%;
				-webkit-flex-wrap: wrap;
				    -ms-flex-wrap: wrap;
				        flex-wrap: wrap;
				td{
					width: 50%;
				}
				.order_table__num{
					width: 100%;
					background: #eff4f8;
				}
				// .order_table__total,
				// .order_table__amount{
				// 	width: 25%;
				// }
			}
		}
		//  tr:nth-child(even) {
		//     background: #eff4f8;
		// }
	}

	.catalog_filter{
		padding: 0;
		width: 100%;
	}
	.filter_trigger__wrap{
		width: 50%;
		border-right: 1px solid @grey;
	}
	.catalog_filter_col{
		width: 50%;
		padding: 10px 15px;

		// align-self: flex-start;
	}
	.showcase_banner {
	    margin-right: 1rem;
	}
	.showcase_slider.slick-slider{
		margin-bottom: 1rem;
	}
	.cart_list.list_full{
		.cart_item{
			position: relative;
			padding: 1rem 0;
			display: flex;
		}
		.old_price{
			display: none;
		}
		// .price_wrap{
		// 	min-width: 150px;
		// }
		.cart_item__remove{
			position: absolute;
			top: 0;
			height: 100%;
			right: 0;
			display: flex;
			-webkit-align-items: center;
			        align-items: center;
			-webkit-justify-content: center;
			        justify-content: center;
		}
		// .cart_item__pic, .cart_item__content{
		// 	margin-bottom: 1rem;
		// }
		.change_num{
			margin: 10px 0;
			-webkit-justify-content: flex-end;
			        justify-content: flex-end;
		}
	}
	.order_form__total, .cart_total{
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
	}
	.order_form__comment, .promo_input{
		width: 100%;
		-webkit-flex-shrink: 0;
		        flex-shrink: 0;
	}
	.cart_list.list_full{
		.item_price{
			margin-left: 0;
		}
		// .cart_item{
		// 	-webkit-flex-wrap: wrap;
		// 	    -ms-flex-wrap: wrap;
		// 	        flex-wrap: wrap;
		// }
		// .cart_item__main{
		// 	-webkit-flex-shrink: 1;
		// 	        flex-shrink: 1;
		// }
		// .item_price{
		// 	display: none;
		// }
		// .price_wrap{
		// 	margin-left: 1rem;
		// }
	}
	.cart_item__details{
		padding-right: 3rem;
		text-align: center;
		-webkit-flex-direction: column;
		    -ms-flex-direction: column;
		        flex-direction: column;
	    // text-align: right;
		// width: 100%;
		// padding-left: 155px;
		// -webkit-justify-content: flex-start;
		//         justify-content: flex-start;
	}
}



@media(max-width:991px){
    .mobile_tabscollapse .tab-content > .tab-pane {
        display: block !important;
        opacity: 1;
        .collapse{
        	border-bottom: 1px solid @grey;
        }
    }
    footer{
		height: 520px;
	}
	.page{
		padding-bottom: 520px;
	}
    header .authorization{
		 margin-left: auto;
	}
    .category_list li {
    	margin: 0;
	    padding: 5px 15px 5px 15px;
	    width: 100%;
	    &:hover{
	    	background: @lblue;
	    }
	}
    .mobile_dropdown{
    	margin: 1px 0;
    	.dropdown__title{
    		border: 1px solid @grey;
    		width: 100%;
    		height: 40px;
    		padding: 10px 15px;
    		&:before{
    			right: 15px;
    		}
    	}
    }
    .slider-for .slick-slide {
	    height: 240px;
	}
	.mobile_tabscollapse .collapse_trigger{
		background: @lblue;
		&.collapsed{
			background: #FFF;
		}
	}
    .catalog_list{
    	padding-top: 1rem;
    	.item_card{
    		margin-bottom: 1rem;
    	}
    }
    .cart_list .cart_item{
    	display: block;
    	.old_price, .current_price{
    		margin-top: 10px;
    	}
    }
    .cart_item__remove{
    	position: absolute;
    	top: 15px;
    	right: 0;
    }
    .cart_total{
    	display: block;
    }
    .side_filter__item.collapse_wrap__item {
	    border-bottom: 1px solid #eff4f8;
	    padding: 0 15px;
	}
	.side_filter__item.collapse_wrap__item:last-child{
		border-bottom: none;
	}
	.collapse_wrap__item .collapse_link{
		padding: 10px 0;
	}
	.filterModal .modal-dialog{
		max-width: 400px;
	}
    .side_filter__head {
    	background: @lblue;
    	margin-bottom: 1rem;
    	h3{
    		margin: 5px 0;
    	}
    	.close{
			position: absolute;
			right: 0;
			top: 10px;
			display: block;
		}
	}
    .breadcrumb{
    	border-top: none;
    	padding-top: 0;
    }
    .category_label{
    	font-size: 13px;
    }
    .filter_trigger__wrap{

    	.modal-trigger{
    		display: block;
    		width: 100%;
    		padding: 1rem;
    		background: @lblue;
    		// text-align: center;
    		.trans;
    		&:hover{
    			background: @grey;
    		}
    	}

    }
    .filter_trigger__wrap{
    	display: block;
    }
    .item_card .item_labels{
    	top: 1.2rem;
    	left: 0;
    }
    .item_card .title{
    	margin-bottom: 1.5rem;
    }
    footer{
		.subscribe_form{
			margin-bottom: 2.5rem;
			.btn{
				width: 100%;
				margin-top: 5px;
				position: static;
				font-size: 14px;
			}
			.form-control{
				font-size: 13px;
			}
		}
	}

    .header_main__bottom{
    	background: @lblue;
    	padding: 5px 15px;
    	margin-top: 15px;
    	margin-left: -15px;
    	margin-right: -15px;
    	position: relative;
    	flex: 0 0 100%;
    	// &:after{
    	// 	content: "";
    	// 	position: absolute;
    	// 	height: 1px;
    	// 	background: @lblue;
    	// 	width: 100%;
    	// 	left: 0;
    	// 	top: -3.2rem;
    	// }

    }
    .modal-lg {
	    max-width: 96%;
	}
    .subscribe_form{
    	max-width: 400px;
    	margin-left: auto;
    	margin-right: auto;
    }

    .mobile_tabscollapse .card-header{
    	// border-color: @grey;
    	border: none;
    }

	.mobile_tabscollapse .collapse {
	  display: block !important;
	  height: 0px;
	  overflow: hidden;
	}

	.mobile_tabscollapse .collapse.show {
	  height: auto !important;
	}

	.cart_item{
		h3{
			font-size: 0.9rem;
		}
	}






	 .mobile-offcanvas{
	    visibility: hidden;
	    transform:translateX(-100%);
	    border-radius:0;
	    display:block;
	    position: fixed;
	    top: 0; left:0;
	    height: 100%;
	    z-index: 1200;
	    width:80%;
	    max-width: 500px;
	    overflow-y: scroll;
	    overflow-x: hidden;
	    background: @lblue;
	    transition: visibility .2s ease-in-out, transform .2s ease-in-out;
	  }

	  .mobile-offcanvas.show{
	    visibility: visible;
	    transform: translateX(0);
	  }
	  .offcanvas-header{
	  	display: block;
	  	background: @lblue;
	  	.back_btn{
	  		border: none;
	  		background: none;
	  		&:hover{
	  			// color: @purp;
	  		}
	  	}
	  }

	  .side_menu__link.active + .side_menu__sub{
	  	display: block;
	  }
	  .side_menu__sub{
	  	position: static;
	  	width: 100%;
	  	padding-left: 0 !important;
	  	-webkit-box-shadow: none;
	  	        box-shadow: none;
	  }
	  .side_menu li a{
	  	padding-left: 42px;
	  }
	  .cart_list.list_full .current_price{
	  	margin-top: 0;
	  }

}


@media screen and (min-width: 769px){
	.elements_slider{
		display: flex;
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
		    padding-top: 0;
		    margin-left: -15px;
		    margin-right: -15px;
		.elements_col{
			width: 33.3%;
			padding: 15px;
			margin-top: 15px;
			margin-bottom: 15px;
			align-items: stretch
		}
		.elements_card{
			height: 100%;

		}
	}

}

@media screen and (max-width: 768px){
	.article_card{
		display: block;
	}
	.ul_dt li{
		padding-right: 0;
	}
	.article_card__pic,
	.article_card__info{
		margin-bottom: 1rem;
	}

	// .order_form__content{
	// 	max-width: 600px;
	// }
	.cart_item__main{
		-webkit-flex-shrink: 1;
		        flex-shrink: 1;
	}
	.cart_list.list_full .cart_item{
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
	    .item_price{
	    	display: none;
	    }
	}
	.cart_item__details{
		width: 100%;
		padding-top: 10px;
		padding-right: 0;
		-webkit-flex-shrink: 1;
		        flex-shrink: 1;
	    -webkit-flex-direction: row;
	        -ms-flex-direction: row;
	            flex-direction: row;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
	}
	.cart_list.list_full .cart_item__remove{
		position: static;
	}
	.cart_list.list_full .change_num{
		margin:0;
	}
	.list_full .price_wrap{
		margin-left: auto;
	}
	.specifications li,
	.list_TwoCol1 li{
		width: 100%;
	}
	// .elements_col{
	// 	padding-left: 16px;
	// }
	.slider_v1 .slick-arrow{
	display: none !important;
}
	.collapse .slider_v1 .slick-list{
		margin: 0;
	}
	.catalog_filter_col{
		font-size: 13px;
		.dropdown__title{
			width: 100%;
		}
	}
	.news_slider .news_card{
		padding: 1rem 0 3.5rem 0;
	}
	.news_card .news_card__bottom{
		padding-left: 0;
	}
	header .logo{
		max-width: 180px;
		min-width: 0;
		display: block;
	}
	.contact_phone{
		font-size: 16px;
		margin-bottom: 0;
		.bigger{
			font-size: 20px;
		}
	}
	.list_ThreeCol{
		-webkit-column-count: 2;
    	-moz-column-count: 2;
    	column-count: 2;
	}
	.error_wrap {
		height: 480px;
		background: url(../images/error_bg.png) no-repeat center/cover;

		h1{
			font-size: 26px;
		}
	}
	.error_block_zzz {
    max-width: 300px;
}
.scroll_up{
	display: none !important;
}
.page_text__item{
	display: none;
}
.pagination .page-item:nth-child(2){
	margin-left: 0;
}
.slider_v1 .slick-dots{
	top: 10px;
	// bottom: 10px;
	width: 100%;
	right: auto;
	margin-bottom: 0;
}
.order_form__confirm,
.cart_total .btn{
	margin: auto;
}
.cart_total{

	.promo_input{
		margin-bottom: 1.2rem;
	}
}
.order_form__content{
	padding: 0;
}
.order_form__total{
	display: block;
}
.order_form__wrap{
	h2{
		margin-bottom: 0;
	}
	.order_form__wrap h3{
		margin-bottom: 1em;
	}
}
.radio_cards{
	-ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin: 0 0;
    // white-space: nowrap;
    overflow: auto;
    // display: inline-block;
    .radio_card{
    	// float: left;
    	width: 220px;
    	flex: none;
    	padding: 0;
    	margin-right: 15px;
    	max-width: 100%;

    }
}
.radio_card input[type=radio]:checked+.radio_card__content{
	-webkit-box-shadow: none;
	        box-shadow: none;
}
.order_form__total{
	padding-bottom: 0;
}
.order_form__confirm{
	text-align: center;
}
.col-xxs-6{
		-ms-flex: 0 0 50%;
	    flex: 0 0 50%;
	    max-width: 50%;
    }
    .cart_list.list_full .current_price{
    	text-align: right;
    }
}

@media screen and (max-width: 579px){
	.footer_bottom{
		font-size: 12px;
	}

	.catalog_filter{
		margin-left: -15px;
		margin-right: -15px;
		width: auto;
	}
	.mobile_dropdown{
		margin-left: -15px;
		margin-right: -15px;
		background: @lblue;
	}
	.catalog_filter_col{
		.dropdown-menu{
			max-width: 100%;
			.dropdown-item{
				white-space: normal;
			}
			// left: auto;
			// right: 0;
		}
	}

	.cart_total{
		text-align: center;
	}
	.cart_item__remove{
		width: 25px;
		min-width: 25px;
	}
	.map_wrap{
		height: 250px;
		// ymaps{
		// 	position: absolute;
		// 	width: 100%;
		// 	height: 100%;
		// }
	}
	header .logo{
		width: 135px;
	}
	.contact_phone{
		font-size: 13px;
		.icon-header-phone-icon{
			display: none;
		}
		.bigger{
			font-size: 15px;
		}
	}
	.aks-file-upload-preview{
		width: 100%;
		max-width: 100%;
	}
	.cart_item__pic{
		margin-right: 15px !important;
	}
	.elements_card{
		max-width: 220px;
		margin: auto;
	}
	.mobile_tabscollapse{
		margin-left: -15px;
		margin-right: -15px;
	}
	.cart_list .cart_item {
		.current_price{
			font-size: 0.8rem;
		}
		.old_price{
			font-size: 12px;
		}
		h3 {
	    	font-size: .8rem;
	    }
	    .change_num .quantity-field{
	    	width: 45px;
	    	margin: 0 6px;
	    }
	}
	.cart_total{
		.btn_custom{
			padding-left: 20px;
			padding-right: 20px;
			width: 100%;
			max-width: 280px;
		}
	}
	.total_sum{
		margin: 10px 0 5px 0
	}
	.slider-for .slick-slide{
		height: auto;
	}
	// header{
	// 	.contact_phone{
	// 		margin-top: 1.6rem;
	// 	}
	// }
	.elements_card{
		// max-width: 280px;
		// margin-left: auto;
		// margin-right: auto;
		// text-align: center;
	}
	.article_card__pic{
		width: 100%;
		max-width: 400px;
		margin-right: 0;
		min-width: 0;
	}
	.slider_v1 .slick-list{
		margin: 0;
	}
	.slider_v1{
		overflow: hidden;
	}
	// .navbar-toggler {
	//     padding: 0;
	//     position: absolute;
	//     top: 5px;
	//     right: 15px;
	// }


	.header_cart__icon{
		width: 35px;
		-webkit-justify-content: flex-end;
		        justify-content: flex-end;
	}
	.header_cart__count{
		left: 7px;
	}
	.header_cart__txt{
		display: none;
	}
	.nav-tabs.tabs_custom2 .nav-item .nav-link{
		min-width: 0;
	}
	// .catalog_filter_col{
	// 	width: 100%;
	// }

	.cart_item__pic {
    width: 90px;
    min-width: 90px;
    max-width: 90px;
}
.cart_item__remove{
	position: absolute;
	top: 0.5rem;
	right: 0;

}
.slider_v1 .slick-arrow{
	display: none !important;
}
.slider_v1{
	padding-top: 40px;
	margin-top: 0 !important;
}


h2{
	font-size: 1.1rem;
}
h1{
	font-size: 1.1rem;
}
.content h2,
.content h3{
	font-size: 1rem;
}
.breadcrumb{
	margin-bottom: 1.2rem;
}
.content{
	margin-bottom: 0;
}
// .cart_list.list_full .cart_item{
// 	display: inline-block;
// 	width: 100%;
// 	.price_wrap{
// 		float: right;
// 		min-width: 0;
// 	}
// }
// .cart_list.list_full .change_num{
// 	float: left;
// }
.promo_input {
	padding-right: 115px;
	.form-control{
		padding: 5px 8px;
	}
	.btn{
	font-size: 13px;
	padding-left: 15px;
	padding-right: 15px;
	}
}
.order_table{
	font-size: 12px;
	.order_table__num{
		font-size: 14px;
		.med;
	}
}
}



@media screen and (max-width: 520px){
	.col-xs-6, .col-xxs-6{
		flex: 0 0 100%;
    	max-width: 100%;
	}
	.list_ThreeCol{
		-webkit-column-count: 1;
    	-moz-column-count: 1;
    	column-count: 1;
	}
	.news_card__pic{
		height: auto;
		img{
			position: static;
		}
	}
	.user_email{
		max-width: 120px;
	}
	.header_location__name{
		max-width: 180px;
	}


}


@media screen and (max-width: 359px){
	.navbar-toggler span{
		width: 20px;
	}
	header .logo{
		width: 120px;
	}
	.header_location__name{
		max-width: 110px;
	}
}




