@font-face {
	font-family: Montserrat-Regular;
	src: url(../fonts/Montserrat-Regular.eot);
	src: url(../fonts/Montserrat-Regular.eot?#iefix) format('embedded-opentype'), 
	url(../fonts/Montserrat-Regular.woff) format('woff'), 
	url(../fonts/Montserrat-Regular.ttf) format('truetype'), 
	url(../fonts/Montserrat-Regular.svg) format('svg');
	font-weight: inherit;
	font-style: normal
}


@font-face {
	font-family: Montserrat-Medium;
	src: url(../fonts/Montserrat-Medium.eot);
	src: url(../fonts/Montserrat-Medium.eot?#iefix) format('embedded-opentype'), 
	url(../fonts/Montserrat-Medium.woff) format('woff'), 
	url(../fonts/Montserrat-Medium.ttf) format('truetype'), 
	url(../fonts/Montserrat-Medium.svg) format('svg');
	font-weight: inherit;
	font-style: normal
}

@font-face {
	font-family: Montserrat-SemiBold;
	src: url(../fonts/Montserrat-SemiBold.eot);
	src: url(../fonts/Montserrat-SemiBold.eot?#iefix) format('embedded-opentype'), 
	url(../fonts/Montserrat-SemiBold.woff) format('woff'), 
	url(../fonts/Montserrat-SemiBold.ttf) format('truetype'), 
	url(../fonts/Montserrat-SemiBold.svg) format('svg');
	font-weight: inherit;
	font-style: normal
}


@font-face {
	font-family: Montserrat-Bold;
	src: url(../fonts/Montserrat-Bold.eot);
	src: url(../fonts/Montserrat-Bold.eot?#iefix) format('embedded-opentype'), 
	url(../fonts/Montserrat-Bold.woff) format('woff'), 
	url(../fonts/Montserrat-Bold.ttf) format('truetype'), 
	url(../fonts/Montserrat-Bold.svg) format('svg');
	font-weight: inherit;
	font-style: normal
}
@font-face {
	font-family: Montserrat-ExtraLight;
	src: url(../fonts/Montserrat-ExtraLight.eot);
	src: url(../fonts/Montserrat-ExtraLight.eot?#iefix) format('embedded-opentype'), 
	url(../fonts/Montserrat-ExtraLight.woff) format('woff'), 
	url(../fonts/Montserrat-ExtraLight.ttf) format('truetype'), 
	url(../fonts/Montserrat-ExtraLight.svg) format('svg');
	font-weight: inherit;
	font-style: normal
}
.reg{
	font-family: Montserrat-Regular;
}
.bold{
	font-family: Montserrat-Bold;
}
.sbold{
	font-family: Montserrat-SemiBold;
}
.med{
	font-family: Montserrat-Medium;
}
.light{
	font-family: Montserrat-ExtraLight;
}




